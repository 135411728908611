angular.module('webremote.phonebook').controller('phonebookManagementCtrl',
  function ($scope, $uibModal, $log, modalService, objectManagerService) {
    $scope.objectManager = objectManagerService.getObjectManager()
    $scope.viewLevel = { levelArray: [] }

    // Pagination properties
    $scope.currentPage = 1
    $scope.itemsPerPage = 17

    // Sort properties
    $scope.reverseSorting = false

    // Phonebook entry properties and methods
    $scope.selectedEntry = {}

    // Action Button Bar
    $scope.createAction = {
      cb: () => {
        $scope.objectManager.create('phoneBookEntry', $scope.selectedEntry.member, true).then(() => {
          $scope.newPhoneBookEntry()
        }, () => {

        })
      }
    }

    $scope.destroyAction = {
      cb: () => {
        const modalConfig = {
          title: 'Confirm',
          text: 'Are you sure to delete ' + $scope.selectedEntry.member.name + '?'
        }

        modalService.confirmModal(modalConfig, $scope, function (response) {
          if (response) {
            $scope.selectedEntry.destroy()
            $scope.newPhoneBookEntry()
          }
        })
      }
    }

    $scope.updateAction = {
      cb: () => $scope.selectedEntry.update()
    }

    $scope.cloneAction = {
      cb: () => $scope.selectedEntry.clone()
    }

    // Controller Navigation methods
    $scope.newPhoneBookEntry = function () {
      $scope.objectManager.getDefault('phoneBookEntry').then((phoneBookEntry) => {
        $scope.$evalAsync(() => {
          $scope.selectedEntry.member = angular.copy(phoneBookEntry)
          $scope.activePill = 'new'
        })
      })
    }

    $scope.selectUser = function (uuid) {
      $scope.objectManager.object(uuid, 'phoneBookEntry').then(phoneBookEntry => {
        $scope.$evalAsync(() => {
          $scope.newUserFlag = false
          $scope.selectedEntry = angular.copy(phoneBookEntry)
          $scope.activePill = $scope.selectedEntry.member.uuid
        })
      })
    }

    $scope.filterHideWildcard = function (phoneBookEntry) {
      if (phoneBookEntry.member.name === '<unknown caller>') {
        const sipAccount = $scope.objectManager.objectSync(phoneBookEntry.member.sip.account, 'sipAccount')
        if (sipAccount !== undefined && sipAccount.member.hideWildcardPhoneBookEntry) {
          return false
        }
      }
      return true
    }

    // Avatar methods
    $scope.uploadFile = function (event) {
      var files = event.target.files
      if (files[0]) {
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
          $scope.image = files[0]

          var reader = new window.FileReader()

          reader.onloadend = function () {
            $scope.selectedEntry.member.avatar = reader.result
          }

          try {
            reader.readAsDataURL(files[0]) // reads the data as a URL
          } catch (e) {
            $log.debug('error on image preview: ' + e)
            $scope.resetAvatar()
          }
        } else {
          $log.debug('wrong file type')
        }
      } else {
        $scope.resetAvatar()
      }
    }

    $scope.resetAvatar = function () {
      $scope.selectedEntry.member.avatar = ''
    }

    // Log methods
    $scope.showLog = function (uuid) {
      $scope.showLogForCallUuid = uuid
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'directives/debug/journal/modalTemplate.html',
        controller: 'journalSipCall',
        size: 'lg',
        scope: $scope
      })
    }

    // Initialize methods
    $scope.newPhoneBookEntry()
  })
