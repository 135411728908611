'use strict'
// import adapter from 'webrtc-adapter'

angular.module('webremote.service.audioPlayer', []).factory('audioPlayerService', function ($injector, httpService, toastr) {
  var service = {}
  const musicMetadata = require('music-metadata-browser')

  class AudioPlayer {
    constructor () {
      this.audioElement = undefined
      this.currentItem = ''
      this.lastPlayedItem = ''
      this.visible = false
      this.meta = {}
      this.lastError = {}
      this.loadinStates = {
        meta: false
      }
      this.encodedCover = ''

      this.localPeerConnection = null
      this.UseWebRtc = false
      this.systemConfig = {}
      this.WebRTCStream = null
      this.tappedStream = ''
      this.iceCandidates = []
      this.iceCandidatesTimer = null
      this.offerSdp = {}
      this.webAudio = {}
      this.webAudioUuid = ''

      this.setPlayObject.bind(this)
    }

    async startConnection () {
      let serverConfiguration = {}
      if (this.systemConfig.member.audio.listenInMode.webRtc.stunServer) {
        serverConfiguration = { iceServers: [{ urls: 'stun:' + this.systemConfig.member.audio.listenInMode.webRtc.stunServer }] }
      }
      this.localPeerConnection = new RTCPeerConnection(serverConfiguration)
      this.localPeerConnection.addEventListener('icecandidate', this.handleConnection.bind(this))
      this.localPeerConnection.addEventListener('iceconnectionstatechange', this.handleConnectionChange.bind(this))
      this.localPeerConnection.addEventListener('track', async (event) => {
        document.getElementById('audio').srcObject = event.streams[0]
        // const [remoteStream] = event.streams
        // this.localPeerConnection.addTrack(remoteStream)
      })

      await navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((mediastream) => {
        this.WebRTCStream = mediastream
      }).catch((error) => {
        console.log('navigator.getUserMedia error: ', error)
      })

      // this.localPeerConnection.addTransceiver('audio', { streams: [this.WebRTCStream], direction: 'recvonly' })
      // this.WebRTCStream.getTracks().forEach(track => {
      //   this.localPeerConnection.addTrack(track, this.WebRTCStream)
      // })
      this.localPeerConnection.createOffer({ offerToReceiveAudio: true }).then((description) => {
        this.localPeerConnection.setLocalDescription(description).then(() => {
          this.offerSdp = description
        }).catch((error) => {
          console.log('localPeerConnection.setLocalDescription error: ', error)
        })
      }).catch((error) => {
        console.log('localPeerConnection.createOffer error: ', error)
      })
    }

    startIceSendTimer (duration, action) {
      if (this.iceCandidatesTimer) {
        clearTimeout(this.iceCandidatesTimer)
      }

      this.iceCandidatesTimer = setTimeout(action, duration)
    }

    handleConnection (event) {
      if (event.candidate) {
        if (event.candidate.candidate !== '') {
          this.iceCandidates.push(JSON.stringify(event.candidate.toJSON()))
          this.startIceSendTimer(300, this.handleIceGatheringChange.bind(this))
        }
      }
    }

    handleConnectionChange (event) {
      const peerConnection = event.target
      if (peerConnection.iceConnectionState === 'disconnected') {
        this.close()
      }
      if (peerConnection.iceConnectionState === 'failed') {
        toastr.error('WebRTC ICE failed. Please try to set another STUN server.', 'Error', {
          closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
        })
        this.close()
      }
    }

    handleIceGatheringChange () {
      const om = $injector.get('objectManagerService').getObjectManager()

      om.getDefault('webAudio').then(function (defaultObj) {
        const webAudio = {}
        webAudio.member = angular.copy(defaultObj)
        webAudio.member.tappedStream = this.tappedStream
        webAudio.member.method = {
          webRtc: {
            offerSdp: JSON.stringify(this.offerSdp),
            answerSdp: '',
            offerIce: this.iceCandidates,
            answerIce: []
          }
        }

        // ToDo: Check if object exists and delete it
        om.create('webAudio', webAudio.member).then(function (uuid) {
          this.webAudioUuid = uuid
          om.object(uuid, 'webAudio', true).then(function (webAudio) {
            this.webAudio = webAudio
          }.bind(this))
          setTimeout(this.waitForAnswerSdp.bind(this), 50)
        }.bind(this))
      }.bind(this))
    }

    waitForAnswerSdp () {
      if (typeof this.webAudio !== 'undefined' && typeof this.webAudio.member !== 'undefined') {
        const om = $injector.get('objectManagerService').getObjectManager()
        om.object(this.webAudio.member.uuid, 'webAudio', true).then(function (webAudio) {
          this.webAudio = webAudio
        }.bind(this))
      }
      if (typeof this.webAudio.member === 'undefined' || !this.webAudio.member.method.webRtc.answerSdp || this.webAudio.member.method.webRtc.answerIce.length === 0) {
        // ToDo measure time and break if it is not working after some amount of time (e.g. if the backend fails)
        setTimeout(this.waitForAnswerSdp.bind(this), 500)
      } else {
        this.localPeerConnection.setRemoteDescription(JSON.parse(this.webAudio.member.method.webRtc.answerSdp))
        for (const iceCandidate of this.webAudio.member.method.webRtc.answerIce) {
          this.localPeerConnection.addIceCandidate(JSON.parse(iceCandidate))
            .then(() => {
            }).catch((error) => {
              console.log('addIceCandidate failed with error:', error)
            })
        }
      }
    }

    async setPlayObject (playObject, metaDataOverride) {
      // Reset meta information
      this.meta = {}
      // Stop running stream
      this.stop()

      // HTTP and WebRTC listenIn
      if (playObject.member !== undefined && playObject.member.objectClass === 'stream') {
        const om = $injector.get('objectManagerService').getObjectManager()
        await om.collection('systemConfig').then((v) => {
          this.systemConfig = Array.from(v.values())[0]
        })
        this.UseWebRtc = Object.prototype.hasOwnProperty.call(this.systemConfig.member.audio.listenInMode, 'webRtc')

        if (location.protocol !== 'https:' && location.hostname !== 'localhost' && this.UseWebRtc) {
          toastr.warning('WebRTC ListenIn Mode selected, but not using HTTPS. Falling back to Http ListenIn.', 'Warning: No HTTPS', {
            closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'
          })
          this.UseWebRtc = false
        }

        this.tappedStream = playObject.member.uuid
        if ((location.protocol === 'https:' || location.hostname === 'localhost') && this.UseWebRtc) {
          await this.startConnection()
        } else {
          this.currentItem = '/webapi/http/listen/' + playObject.member.uuid
        }

      // Handle files from archive
      } else {
        this.importMetadata(this.getFilePath(playObject))
        this.currentItem = this.getFilePath(playObject)
        this.UseWebRtc = false
      }

      // Handle meta data override
      if (metaDataOverride) {
        this.meta = metaDataOverride
      }

      // Auto play, if ready
      this.audioElement.addEventListener('loadeddata', () => {
        if (this.audioElement.readyState >= 3) {
          this.audioElement.play()
        }
      })

      this.visible = true
    }

    async importMetadata (url) {
      this.loadinStates.meta = true

      musicMetadata.fetchFromUrl(url, { skipPostHeaders: this.isLivestream() }).then(meta => {
        this.meta = meta
        if (Array.isArray(this.meta.common.picture) && this.meta.common.picture[0].data !== undefined) {
          let base64String = ''
          for (var i = 0; i < this.meta.common.picture[0].data.length; i++) {
            base64String += String.fromCharCode(this.meta.common.picture[0].data[i])
          }
          this.meta.common.picture[0].base64String = 'data:' + this.meta.common.picture[0].format + ';base64,' + window.btoa(base64String)
        }
      })

      this.loadinStates.meta = false
    }

    isLoading (key) {
      return this.loadinStates[key]
    }

    isLivestream () {
      if (this.audioElement.duration !== undefined) {
        return this.audioElement.duration.toString() === 'Infinity'
      } else {
        return false
      }
    }

    getFilePath (file) {
      return httpService.webapiUrl + 'archive' + file.path + file.name
    }

    toggleVisibility () {
      this.visible = !this.visible
    }

    setVolume (volume) {
      if (this.audioElement) {
        this.audioElement.volume = volume
      }
    }

    setAudioElement (audioElement) {
      this.audioElement = audioElement
    }

    async play () {
      if (this.UseWebRtc) {
        if (this.tappedStream) {
          await this.startConnection()
        }
      } else {
        if (this.currentItem.length === 0 || this.currentItem.includes('/webapi/http/listen/stop/')) {
          this.currentItem = this.lastPlayedItem
        }
      }
      // Auto play, if ready
      this.audioElement.addEventListener('loadeddata', () => {
        if (this.audioElement.readyState >= 3) {
          this.audioElement.play()
        }
      })
    }

    stop () {
      // Save current item
      if (!this.currentItem.includes('/webapi/http/listen/stop/') && this.currentItem !== '') {
        this.lastPlayedItem = this.currentItem
      }
      this.audioElement.pause()
      if (this.tappedStream) {
        this.currentItem = '/webapi/http/listen/stop/' + this.tappedStream
      }
      this.audioElement.currentTime = 0
      if (this.UseWebRtc) {
        if (typeof this.localPeerConnection !== 'undefined' && this.localPeerConnection !== null) {
          this.localPeerConnection.close()
        }
        this.localPeerConnection = null
        if (typeof this.WebRTCStream !== 'undefined' && this.WebRTCStream !== null) {
          this.WebRTCStream.getTracks().forEach(function (track) {
            if (track.readyState === 'live') {
              track.stop()
            }
          })
        }
        this.WebRTCStream = null
        if (typeof this.webAudio !== 'undefined' && typeof this.webAudio.member !== 'undefined') {
          this.webAudio.destroy()
          this.webAudio = {}
        }
        this.webAudioUuid = ''
        this.iceCandidates = []
        this.offerSdp = {}
      }
    }

    close () {
      this.stop()
      this.visible = false
      this.meta = {}
    }

    seekTo (value) {
      this.audioElement.currentTime = Math.round(value)
    }

    streamStoppedCallback (uuid) {
      if (this.currentItem.search(uuid) !== -1) {
        this.close()
      }
    }
  }

  service.control = new AudioPlayer()

  return service
})
